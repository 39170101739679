import dayjs from "dayjs";
import { ACCESS_TOKEN, FilterTypes, GLOBAL_CONFIG, GLOBAL_CONFIG_KEYS } from "../constant/constant";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { LocalStorageUtils } from "./local-storage-utils";
import { isMobile, osName } from "mobile-device-detect";
import lodash from "lodash";
import { toast } from "react-toastify";
import Axios from "../config/axios-config";

export const formatDate = (date: any, format = "") => {
  return dayjs(date).format(format || JSON.parse(LocalStorageUtils.getStorage(GLOBAL_CONFIG) as string)?.[GLOBAL_CONFIG_KEYS.DATE_FORMAT]);
}

export const isAuth = () => {
  if (!LocalStorageUtils.getStorage(ACCESS_TOKEN)) return false;
  return true;
}

export const zodPatternValidation = (z: any, pattern: RegExp, message: string) => {
  return z.refine((value: any) => {
    if (value) return pattern.test(value);
    else return true;
  }, message);
}

const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

const convert: any = (num: number) => {
  if (num === 0) {
    return '';
  } else if (num < 10) {
    return units[num];
  } else if (num === 10) {
    return tens[1]
  } else if (num < 20) {
    return teens[num - 11];
  } else if (num < 100) {
    return tens[Math.floor(num / 10)] + ' ' + units[num % 10];
  } else {
    return units[Math.floor(num / 100)] + ' Hundred ' + convert(num % 100);
  }
};

export const convertToIndianWords: any = (num: number) => {
  if (num === 0) {
    return 'Zero';
  } else if (num >= 10000000) {
    const remainder = num % 10000000;
    const crorePart = Math.floor(num / 10000000);
    const crore = convert(crorePart) + (crorePart > 1 ? ' Crores ' : ' Crore ');
    const rest = convertToIndianWords(remainder);
    return rest !== 'Zero' ? crore + rest : crore;
  } else if (num >= 100000) {
    const remainder = num % 100000;
    const lakhPart = Math.floor(num / 100000);
    const lakh = convert(lakhPart) + (lakhPart > 1 ? ' Lakhs ' : ' Lakh ');
    const rest = convertToIndianWords(remainder);
    return rest !== 'Zero' ? lakh + rest : lakh;
  } else if (num >= 1000) {
    const remainder = num % 1000;
    const thousandPart = Math.floor(num / 1000);
    const thousand = convert(thousandPart) + ' Thousand ';
    const rest = convert(remainder);
    return rest !== 'Zero' ? thousand + rest : thousand;
  } else {
    return convert(num);
  }
};

export const DownloadCSV = (rows: any, fileName: any) => {
  const csv = Papa.unparse(rows);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};

export const formatNumber = (numberToBeFormatted: number, formatType = 'en-IN') => {
  return new Intl.NumberFormat(formatType).format(numberToBeFormatted);
}

export const readFileAsDataURL = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    return reader.readAsDataURL(file);
  });
};

export const getPlatform = () => {
  if (isMobile) {
    if (osName.toLowerCase() === "ios" || osName.toLowerCase() === "mac os") {
      return "ios";
    }
    else {
      return "android";
    }
  }
  else {
    return "web";
  }
}

export const getLocation = (setLocationString: any, showToast: boolean = false, setErrorMessage?: any) => {
  if (setErrorMessage) setErrorMessage("");
  navigator.geolocation.getCurrentPosition((position) => {
    if (position.coords.latitude && position.coords.longitude) {
      setLocationString(`POINT(${position.coords.longitude} ${position.coords.latitude})`);
      if (showToast) {
        toast.success("Current Location Fetched", {
          position: 'top-center',
        });
      }
    }
  }, (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        if (setErrorMessage) setErrorMessage("User denied the request for Geolocation.")
        break;
      case error.POSITION_UNAVAILABLE:
        if (setErrorMessage) setErrorMessage("Location information is unavailable.")
        break;
      case error.TIMEOUT:
        if (setErrorMessage) setErrorMessage("The request to get user location timed out.")
        break;
      default:
        if (setErrorMessage) setErrorMessage("An unknown error occurred.")
    }
  });
}


export function openMap(latitude: any, longitude: any) {
  const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  window.open(url, '_blank');
}

export const filterArrayByArray = ((mainArray: any, excludeArray: any, key = "id") => {
  return lodash.differenceBy(mainArray, excludeArray, key);
});

export const getCommonObjectsBetweenArrays = ((array1: any, array2: any, key = "id") => {
  return lodash.intersectionBy(array1, array2, key);
});

export const arrayToObject = ((array: any, key = "id") => {
  return lodash.keyBy(array, key);
});

export function generateOtp(length?: number): string {
  // Exclude all characters that are hard to tell apart - 0, O, o, I, l, 1
  const allowedChars = "23456789";
  const otpLength = length || 6;
  const otp = [];
  for (let i = 0; i < otpLength; i++) {
    otp.push(allowedChars[Math.floor(Math.random() * allowedChars.length)]);
  }
  return otp.join('');
}

const removeDefaultValues = (obj: any) => {
  delete obj.id;
  delete obj.created_by;
  delete obj.created_date;
  delete obj.updated_by;
  delete obj.updated_date;
}

export const convertToInvoice = async ({ entity, dateKey, invoiceDetails, users_me, emiDetails, itemsData, prefix, navigate, props, detailsId }: ConvertToInvoice) => {
  try {
    const id = invoiceDetails.id;
    // const { data: salesDetailsData } = await Axios.post(`sales_details/entity`,{ [FilterTypes.LIKE]: { invoice_number: `%${prefix?.['Sales Invoice Prefix']?.prefix}%` } });
    // invoiceDetails.invoice_number = prefix?.['Sales Invoice Prefix']?.prefix + (+(salesDetailsData.filtered_rows) + 1);
    invoiceDetails.customer_id = invoiceDetails.customer_id.id || invoiceDetails.customer_id;
    invoiceDetails.payment_mode_id = invoiceDetails.payment_mode_id.id || invoiceDetails.payment_mode_id;
    invoiceDetails.total_refund = 0;
    invoiceDetails.sales_date = invoiceDetails[dateKey];
    delete invoiceDetails[dateKey];
    delete invoiceDetails.current_status;
    delete invoiceDetails.sales_details_id;
    delete invoiceDetails.delivery_date;
    delete invoiceDetails.total_amount_before_round_off;
    invoiceDetails.discount_total = +invoiceDetails.discount_total || 0;
    invoiceDetails.tax_total = +invoiceDetails.tax_total || 0;
    invoiceDetails.total_amount = +invoiceDetails.total_amount || 0;
    invoiceDetails.received_amount = +invoiceDetails.received_amount || 0;
    invoiceDetails.balance_amount = +invoiceDetails.balance_amount || 0;
    invoiceDetails.round_off_amount = +invoiceDetails.round_off_amount || 0;
    invoiceDetails.net_amount = +invoiceDetails.net_amount || 0;
    invoiceDetails.total_quantity = +invoiceDetails.total_quantity || 0;
    removeDefaultValues(invoiceDetails);
    invoiceDetails.created_by = users_me?.id;
    const invoice_prefix = prefix?.['Sales Invoice Prefix']?.prefix;
    invoiceDetails.invoice_prefix = invoice_prefix;
    try {
      const { data: rows } = await Axios.post(`${'sales_details'}/entity/search?includeDeleted=true`, { [FilterTypes.EQUAL_TO]: { invoice_prefix: `${invoice_prefix}` }, [FilterTypes.SORT_BY]: { id: "DESC" }, [FilterTypes.LIMIT]: 1 });
      invoiceDetails.invoice_number = `${invoice_prefix}${+(rows[0].invoice_number.split(invoice_prefix)[1]) + 1}`
    } catch (err) {
      invoiceDetails.invoice_number = `${invoice_prefix}1`;
    }
    const salesDetailsRes = await Axios.post(`sales_details/entity`, invoiceDetails);
    // await Axios.put(`sales_details/entity/${salesDetailsRes.data.id}`, { invoice_number: `S${salesDetailsRes.data.id}` });
    removeDefaultValues(emiDetails);
    for (let i = 0; i < itemsData.length; i++) {
      const item: any = itemsData[i];
      delete item.id;
      delete item[`${entity}_details_id`];
      item.item_master_details_id = item.item_master_details_id.id;
      item.item_master_id = item.item_master_id.id;
      item.sales_details_id = salesDetailsRes.data.id;
      item.discount_amount = +item.discount_amount;
      item.mrp = +item.mrp;
      item.tax_amount = +item.tax_amount;
      item.tax_rate = +item.tax_rate;
      item.total_price = +item.total_price;
      item.unit_price = +item.unit_price;
      item.discount_rate = +item.discount_rate;
      item.quantity = +item.quantity;
      delete item?.low_stock;
      await Axios.post(`sales_items/entity`, item);
    };
    await Axios.put(`${entity}_details/entity/${id}`, { current_status: "closed", sales_details_id: salesDetailsRes.data.id });
    toast.success("Converted To Sales Invoice Successfully", {
      position: toast.POSITION.TOP_RIGHT
    });
    navigate(`/invoice_view?type=sales&id=${salesDetailsRes.data.id}&page=customer&workflow=true${props?.show_record_payment_in === 'true' ? `&show-payment-in=true` : ''}${props?.hide_tax_column === 'true' ? `&hide-tax-column=true` : ""}`,
      { state: { tableBackgroundColor: '#F9E5CF', orgNameColor: '#BF6200', label: invoiceDetails?.workflow_state_label, entityId: 12, seqId: invoiceDetails?.workflow_state_sequence_number, workflow_state_id: invoiceDetails?.workflow_state_id, props } })
  } catch (err) { console.log(err) };
}

export const convertToPurchaseInvoice = async ({ originalInvoiceNo, entity, dateKey, invoiceDetails, users_me, itemsData, prefix, navigate, props, detailsId, advanceAmount }: ConvertToInvoice) => {
  try {
    const id = invoiceDetails.id;
    invoiceDetails.supplier_id = invoiceDetails.supplier_id.id || invoiceDetails.supplier_id;
    invoiceDetails.purchase_date = invoiceDetails[dateKey];
    delete invoiceDetails[dateKey];
    delete invoiceDetails.current_status;
    delete invoiceDetails.purchase_details_id;
    delete invoiceDetails.total_amount_before_round_off;
    invoiceDetails.discount_total = +invoiceDetails.discount_total || 0;
    invoiceDetails.paid_amount = advanceAmount || 0;
    invoiceDetails.tax_total = +invoiceDetails.tax_total || 0;
    invoiceDetails.total_amount = +invoiceDetails.total_amount || 0;
    invoiceDetails.balance_amount = +invoiceDetails.balance_amount || 0;
    invoiceDetails.round_off_amount = +invoiceDetails.round_off_amount || 0;
    invoiceDetails.net_amount = +invoiceDetails.net_amount || 0;
    invoiceDetails.total_quantity = +invoiceDetails.total_quantity || 0;
    invoiceDetails.original_invoice_number = originalInvoiceNo;
    removeDefaultValues(invoiceDetails);
    const invoice_prefix = prefix?.['Purchase Invoice Prefix']?.prefix;
    invoiceDetails.invoice_prefix = invoice_prefix;
    delete invoiceDetails.advance_amount;
    try {
      const { data: rows } = await Axios.post(`${'purchase_details'}/entity/search?includeDeleted=true`, { [FilterTypes.EQUAL_TO]: { invoice_prefix: `${invoice_prefix}` }, [FilterTypes.SORT_BY]: { id: "DESC" }, [FilterTypes.LIMIT]: 1 });
      invoiceDetails.invoice_number = `${invoice_prefix}${+(rows[0].invoice_number.split(invoice_prefix)[1]) + 1}`
    } catch (err) {
      invoiceDetails.invoice_number = `${invoice_prefix}1`;
    }
    await Axios.put(`${entity}_details/entity/${id}`, { advance_amount: +invoiceDetails.payment_mode_id === 6 ? (advanceAmount || 0) : 0, original_invoice_number: originalInvoiceNo })
    const purchaseDetailsRes = await Axios.post(`purchase_details/entity`, invoiceDetails);
    // await Axios.put(`purchase_details/entity/${purchaseDetailsRes.data.id}`, { invoice_number: `P${purchaseDetailsRes.data.id}` });
    for (let i = 0; i < itemsData.length; i++) {
      const item: any = itemsData[i];
      delete item.id;
      delete item[`${entity}_details_id`];
      delete item.item_description;
      item.item_master_details_id = item.item_master_details_id.id;
      item.item_master_id = item.item_master_id.id;
      item.purchase_details_id = purchaseDetailsRes.data.id;
      item.discount_amount = +item.discount_amount;
      item.mrp = +item.mrp;
      item.tax_amount = +item.tax_amount;
      item.tax_rate = +item.tax_rate;
      item.total_price = +item.total_price;
      item.unit_price = +item.unit_price;
      item.sales_price = +item.sales_price;
      item.discount_rate = +item.discount_rate;
      item.quantity = +item.quantity;
      await Axios.post(`purchase_items/entity`, item);
      await Axios.put(`${entity}_details/entity/${id}`, { current_status: "closed", purchase_details_id: purchaseDetailsRes.data.id });
      toast.success("Converted To Purchase Invoice Successfully", {
        position: toast.POSITION.TOP_RIGHT
      });
      navigate(`/invoice_view?type=purchase&id=${purchaseDetailsRes.data.id}&page=customer&workflow=true${props?.show_record_payment_in === 'true' ? `&show-payment-in=true` : ''}${props?.hide_tax_column === 'true' ? `&hide-tax-column=true` : ""}`,
        { state: { tableBackgroundColor: '#F9E5CF', orgNameColor: '#BF6200', label: invoiceDetails?.workflow_state_label, entityId: 14, seqId: invoiceDetails?.workflow_state_sequence_number, workflow_state_id: invoiceDetails?.workflow_state_id, props } })
    };
  } catch (err) { console.log(err) };
}

export const generateReportQueryString = (obj: any) => {
  if (Object.keys(obj || {}).length > 0) {
    const queryString = Object.keys(obj).reduce((queryString: string, key: any, currentIndex: number) => {
      let toAdd = ``;
      if (currentIndex === 0) {
        toAdd = `filter=${key}='${obj[key]}'`;
      }
      else {
        toAdd = ` and ${key}='${obj[key]}'`;
      }
      if (currentIndex === Object.keys(obj).length - 1) {
        toAdd = `${toAdd}`;
      }
      return queryString + toAdd;
    }, "");
    return queryString;
  } else return "";
}

export const customerAddress = (invoiceDetails: any, page: string = 'customer'): string => {
  return `${invoiceDetails?.contact_street ? `${invoiceDetails?.contact_street}, ` :
    invoiceDetails?.[page + '_id']?.contact_street ? `${invoiceDetails?.[page + '_id']?.contact_street}, ` : ""}${invoiceDetails?.contact_address ? `${invoiceDetails?.contact_address}${invoiceDetails?.contact_city ? `, ${invoiceDetails?.contact_city}` : ""}${invoiceDetails?.contact_zip_code ? ` - ${invoiceDetails?.contact_zip_code}` : ""}${invoiceDetails?.contact_state ? `, ${invoiceDetails?.contact_state}` : ""}` :
      invoiceDetails?.[page + '_id']?.contact_address ? `${invoiceDetails?.[page + '_id']?.contact_address}${invoiceDetails?.[page + '_id']?.contact_city ? `, ${invoiceDetails?.[page + '_id']?.contact_city}` : ""}${invoiceDetails?.[page + '_id']?.contact_zip_code ? ` - ${invoiceDetails?.[page + '_id']?.contact_zip_code}` : ""}${invoiceDetails?.[page + '_id']?.contact_state ? `, ${invoiceDetails?.[page + '_id']?.contact_state}` : ""}` :
        `${invoiceDetails?.[page + '_id']?.address}${invoiceDetails?.contact_city ? `, ${invoiceDetails?.contact_city}` : invoiceDetails?.[page + '_id']?.contact_city ? `, ${invoiceDetails?.[page + '_id']?.contact_city}` :
          invoiceDetails?.[page + '_id']?.city ? `, ${invoiceDetails?.[page + '_id']?.city}` : ""}${invoiceDetails?.contact_state ? `, ${invoiceDetails?.contact_state}` : invoiceDetails?.[page + '_id']?.contact_state ? `, ${invoiceDetails?.[page + '_id']?.contact_state}` : invoiceDetails?.[page + '_id']?.state ? `, ${invoiceDetails?.[page + '_id']?.state}` : ""}${invoiceDetails?.contact_zip_code ? ` - ${invoiceDetails?.contact_zip_code}` : invoiceDetails?.[page + '_id']?.contact_zip_code ? ` - ${invoiceDetails?.[page + '_id']?.contact_zip_code}` : invoiceDetails?.[page + '_id']?.zip_code ? ` - ${invoiceDetails?.[page + '_id']?.zip_code}` : ""}`}${invoiceDetails?.[page + '_id']?.landmark ? `${invoiceDetails?.[page + '_id']?.landmark}` : ""}`
}

export interface ConvertToInvoice {
  entity: string;
  dateKey: string;
  invoiceDetails: any;
  users_me: any;
  emiDetails?: any;
  itemsData: any;
  prefix: any;
  navigate: any;
  props: any;
  detailsId: any;
  originalInvoiceNo?: string;
  advanceAmount?: number;
}